import './AddEditUserAddress.css'
import React, {useState} from 'react';
import {Button, Card, CardBody, Form, Spinner} from "reactstrap";
import {
    useUpdateAddressMutation,
    useDeleteAddressMutation,
    useSaveAddressMutation
} from "../../../../../app/apiSlice";
import {ReactComponent as Trashcan} from '../../.././../../assets/icons/trash-can-regular.svg';
import {useTranslation} from "react-i18next";
import { useAppSelector } from "../../../../../app/hooks";
import {selectCurrentConsumerUser} from "../../../login/AuthenticationSlice";
import {FormProvider, useForm} from "react-hook-form";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {FormAddressInput} from "../../../../../addresses/FormAddressInput";
import {FormInputField} from "../../../../forms/FormInputField";
import {consumerSavedAddressSchema} from "../../../../../addresses/AddressSchema";
import {Address, AddressVm} from "../../../../input/address/Address";
import {ShippingAddressChoice, SuggestedAddressModel} from "../../../../../addresses/SuggestedAddressModel";
import {validateAddress} from "../../../../../utils/SmartyHelper";

interface AddEditUserAddressProps {
    isEditMode: boolean,
    address?: AddressVm,
    returnToListView: () => void,
}

export const AddEditUserAddress = ({ isEditMode, address, returnToListView }: AddEditUserAddressProps ) => {
    const { t } = useTranslation();
    const consumer = useAppSelector(selectCurrentConsumerUser);

    const [saveAddressMutation, {isLoading: isSaving}] = useSaveAddressMutation();
    const [updateAddressMutation, {isLoading: isUpdating}] = useUpdateAddressMutation();
    const [deleteAddressMutation, {isLoading: isDeleting}] = useDeleteAddressMutation();

    const [enteredAddress, setEnteredAddress] = useState<AddressVm>();
    const [suggestedShippingAddress, setSuggestedShippingAddress] = useState<Address>();
    const [showSuggestShippingAddressModal, setShowSuggestShippingAddressModal] = useState(false);

    const initialAddress = {
        street: enteredAddress?.street,
        city: enteredAddress?.city,
        stateCode: enteredAddress?.stateCode,
        zip: enteredAddress?.zip
    } as Address;

    const suggestedShippingAddressVm = {
        street: suggestedShippingAddress?.street,
        city: suggestedShippingAddress?.city,
        stateCode: suggestedShippingAddress?.stateCode,
        zip: suggestedShippingAddress?.zip,
        isDefaultBillingAddress: enteredAddress?.isDefaultBillingAddress,
        isDefaultShippingAddress: enteredAddress?.isDefaultShippingAddress,
    } as AddressVm;

    const form = useForm({
        mode: 'onSubmit',
        resolver: zodResolver(consumerSavedAddressSchema),
        defaultValues: {
            address: {
                street: address?.street ?? "",
                city: address?.city ?? "",
                stateCode: address?.stateCode ?? "",
                zip: address?.zip ?? "",
                isDefaultBillingAddress: address?.isDefaultBillingAddress ?? false,
                isDefaultShippingAddress: address?.isDefaultShippingAddress ?? false
            }
        }
    });

    const {
        handleSubmit,
        control,
        reset,
    } = form;

    const onSubmit = async (data: z.infer<typeof consumerSavedAddressSchema>) => {
        const [match, isVerified] = await validateAddress(data.address);

        if (!isVerified) {
            setEnteredAddress(data.address);
            setSuggestedShippingAddress(match);
            setShowSuggestShippingAddressModal(true);
            return;
        }

        await saveAddress(data.address);

        resetToListView();
    }

    const saveAddress = async (addressRequest: AddressVm) => {
        const saveAddressRequest = {
            Id : address?.id,
            addressId: address?.id,
            consumerId: consumer?.id,
            street: addressRequest.street,
            streetTwo: "",
            city: addressRequest.city,
            stateCode: addressRequest.stateCode,
            zip: addressRequest.zip,
            isDefaultBillingAddress: addressRequest.isDefaultBillingAddress,
            isDefaultShippingAddress: addressRequest.isDefaultShippingAddress,
        };

        if (isEditMode) {
            await updateAddressMutation(saveAddressRequest);
        } else {
            await saveAddressMutation(saveAddressRequest);
        }
    }

    const onConfirmShippingAddress = async (choice: ShippingAddressChoice) => {
        setShowSuggestShippingAddressModal(false);

        if (choice === "entered" && enteredAddress) {
            await saveAddress(enteredAddress);
        } else if (choice === "enter-new") {
            return;
        } else if (choice === "suggested") {
            await saveAddress(suggestedShippingAddressVm);
        }

        resetToListView();
    }

    const onDelete = async (consumerId: number, addressId: number) => {
        await deleteAddressMutation({consumerId, addressId});
        resetToListView();
    }

    const resetToListView = () => {
        reset();
        returnToListView();
    }

    return (
        <div>
            <FormProvider {...form}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Card className="user-address-card" key="addAddress">
                        <CardBody className="d-flex justify-content-between align-items-center">
                            <FormAddressInput
                                control={control}
                                name="address"
                                showSavedAddresses={false}
                                before={
                                    <div className='toggle-group col-12'>
                                        <FormInputField
                                            control={control}
                                            name={"address.isDefaultBillingAddress"}
                                            label={t(`saveAddress.labels.isDefaultBillingAddress`)}
                                            type="switch"
                                            trimOnBlur={false}
                                            defaultChecked={address?.isDefaultBillingAddress}
                                        />
                                        <FormInputField
                                            control={control}
                                            name={"address.isDefaultShippingAddress"}
                                            label={t(`saveAddress.labels.isDefaultShippingAddress`)}
                                            type="switch"
                                            trimOnBlur={false}
                                            defaultChecked={address?.isDefaultShippingAddress}
                                        />
                                    </div>
                                }
                            />
                        </CardBody>
                    </Card>
                    <span
                        className='action-btn-container d-flex flex-column justify-content-start flex-sm-row justify-content-sm-between'>
                        <div className='col-12 col-sm-6 col-md-5 action-btn-group d-flex flex-column flex-sm-row'>
                            <Button
                                type='submit'
                                className='col-12 col-sm-6'
                                disabled={isSaving || isUpdating || isDeleting}
                                color='primary'>
                                {(isSaving || isUpdating) && <Spinner/>}
                                {t(`saveAddress.buttons.save`)}
                            </Button>
                            <Button
                                className='col-12 col-sm-6 edit-btn'
                                onClick={returnToListView}>
                                {t(`saveAddress.buttons.cancel`)}
                            </Button>
                        </div>
                        {isEditMode &&
                            <Button
                            color='danger'
                            className='col-12 col-sm-5 col-md-6 btn-outline-danger delete-btn'
                            disabled={isDeleting || isSaving || isUpdating}
                            onClick={() => (address?.id && consumer?.id) && onDelete(consumer.id, address?.id)}>
                              <Trashcan/>
                              {t('saveAddress.buttons.delete')}
                        </Button>}
                    </span>
                </Form>
            </FormProvider>
            <SuggestedAddressModel
                suggestedAddress={suggestedShippingAddress}
                initialAddress={initialAddress}
                isOpen={showSuggestShippingAddressModal}
                onComplete={onConfirmShippingAddress}
            />
        </div>
    )
}