import React, {useState} from "react";
import classnames from "classnames";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Address} from "../components/input/address/Address";
import {getPrettyAddress} from "../utils/Utils";

export type ShippingAddressChoice = "suggested" | "entered" | 'enter-new';
interface SuggestedAddressModelProps {
    suggestedAddress: Address | undefined;
    initialAddress: Address;
    isOpen: boolean;
    onComplete: (choice: ShippingAddressChoice) => void | Promise<void>;
}

export const SuggestedAddressModel = ({
                                          suggestedAddress,
                                          initialAddress,
                                          isOpen,
                                          onComplete
                                      }: SuggestedAddressModelProps) => {

    const [choice, setChoice] = useState<ShippingAddressChoice>("suggested");

    const suggestedOptionClassName = classnames("checkout-boxed-radio-option", {"checkout-boxed-radio-option-checked": choice === "suggested"});
    const enteredOptionClassName = classnames("checkout-boxed-radio-option", {"checkout-boxed-radio-option-checked": choice === "entered"});


    const handleShippingAddressChoiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChoice(e.target.value as ShippingAddressChoice);
    };

    const handleConfirmClick = () => {
        onComplete(choice);
    }

    return (
        <Modal isOpen={isOpen}
            // toggle={toggleSuggestShippingAddressModal}
               centered={true}
        >
            <ModalHeader
                // toggle={toggleSuggestShippingAddressModal}
            >
                <div className="bolded-text">
                    Verify Your Address
                </div>
            </ModalHeader>
            <ModalBody>
                <>
                    {suggestedAddress ?
                        <div>
                            <div key="0" className={suggestedOptionClassName}>
                                <Label>
                                    <Input type="radio"
                                           name="shippingAddress"
                                           value={"suggested"}
                                           onChange={handleShippingAddressChoiceChange}
                                           checked={choice === "suggested"}
                                    />
                                    <div>
                                        <div className="mb-2 text-primary bolded-text">
                                            Use Suggested Address:
                                        </div>
                                        <div className="px-4">
                                            {getPrettyAddress(suggestedAddress)}
                                        </div>
                                    </div>
                                </Label>
                            </div>
                            <div key="1" className={enteredOptionClassName}>
                                <Label>
                                    <Input type="radio"
                                           name="shippingAddress"
                                           value={"entered"}
                                           onChange={handleShippingAddressChoiceChange}
                                           checked={choice === "entered"}
                                    />
                                    <div>
                                        <div className="mb-2 text-primary">
                                            Use Address As Entered:
                                        </div>
                                        <div className="px-4">
                                            {getPrettyAddress(initialAddress)}
                                        </div>
                                    </div>
                                </Label>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="mb-4">
                                You entered:
                            </div>
                            <div className="mb-4 h5 text-center">
                                {getPrettyAddress(initialAddress)}
                            </div>

                            <div className="alert alert-info">
                                We were unable to verify your address with our shipping providers.
                                Please choose an option below.
                            </div>
                        </div>
                    }
                </>
            </ModalBody>
            <ModalFooter>
                <>
                    {suggestedAddress ?
                        <Button color="primary" onClick={handleConfirmClick}>
                            Confirm
                        </Button>
                        :
                        <>
                            <Button color="secondary" onClick={() => onComplete("enter-new")}>
                                Enter Another Address
                            </Button>
                            <Button color="primary" onClick={() => onComplete("entered")}>
                                Use This Address
                            </Button>
                        </>
                    }
                </>
            </ModalFooter>
        </Modal>
    )
}