import { AddressInputVm } from "../address/Address";
import {MediaQueryTypes, useMediaQuery, useObjState, useSmartyStreets} from "../../../app/hooks";
import { useGetStatesQuery } from "../../../app/apiSlice";
import { Button, FormFeedback, Input, Label, Spinner } from "reactstrap";
import { RequiredAsterisk } from "../utils/RequiredAsterisk";
import {isAddressEmpty, Regexes } from "../../../utils/Utils";
import {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import styles from './ValidatedAddressInput.module.css';
import { useTranslation } from "react-i18next";

interface ValidatedAddressProps {
    value: AddressInputVm
    onChange: (value?: AddressInputVm) => void,
    required?: boolean,
    disableBrowserAutoComplete?: boolean,
    disabled?: boolean,
    isShippingAddress?: boolean,
    suppressError?: boolean, // true if you want to hold off on showing error until the user submits
    emitInvalid?: boolean, // allows this input to emit invalid addresses
    clear?: boolean, // true if you need to clear out the input from the parent
    disableVerification?: boolean
}
export const ValidatedAddressInput = (props: ValidatedAddressProps) => {

    const {
        disableVerification = false
    } = props;
    
    const isPresent = useCallback((val?: string) =>
            !!val?.trim()
    , []);

    const isValidZip = useCallback((zip?: string) =>
        // if it's required, make sure it's present & valid
        // if it's not required, make sure it's valid if it's present
        (isPresent(zip) || props.required ) && Regexes.zipCode.test(zip ?? '')
    , [props.required, isPresent]);

    const isAllValid = useCallback((val: AddressInputVm) =>
        isPresent(val.street) && isPresent(val.city) && isPresent(val.stateCode) && isValidZip(val.zip)
    , [isPresent, isValidZip]);

    const [ value, setValue, setAddress ] = useObjState<AddressInputVm>({
        ...props.value, isValid: isAllValid(props.value)
    });

    const { matches: suggestedAddresses, isVerified, isLoading } = useSmartyStreets(value);
    const { t }  = useTranslation();
    
    const { data: states } = useGetStatesQuery();
    const [focusedInput, setFocusedInput] = useState<string>();
    const suggestionMenuRef = useRef<HTMLDivElement | null>(null);
    const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);

    useEffect(() => {
        const newWholeValue = {
            ...value,
            valid: isAllValid(value),
            isVerified: isVerified,
        };
        setAddress(newWholeValue);

        if (isVerified || !isAddressEmpty(newWholeValue)) {
            emitUpdatedValue(newWholeValue);
        }
    }, [isVerified]);

    // parent can clear value through clear prop
    useEffect(() => {
        if (props.clear) setAddress({});
    }, [props.clear, setAddress])
    
    // if parent wants to change value, they can, but it must be valid
    useEffect(() => {
        if (isAllValid(props.value))
            setAddress(props.value);
    }, [props.value, isAllValid, setAddress])
    
    const onChange = (key: keyof AddressInputVm, e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue(key, newValue);
        setValue('isVerified', false);
        
        const newWholeValue = {
            ...value,
            [key]: newValue,
            isVerified: false,
        };
        emitUpdatedValue(newWholeValue);
    }

    const emitUpdatedValue = (newWholeValue: AddressInputVm) => {
        // only emit valid values, otherwise, nothing
        const isValid = isAllValid(newWholeValue);
        if (isValid || props.emitInvalid) {
            props.onChange({...newWholeValue, isValid});
        } else {
            props.onChange(undefined);
        }
    }
    
    const getAutoComplete = (name: 'address-line1' | 'address-level2' | 'address-level1' | 'postal-code') => {
        if (props.disableBrowserAutoComplete)
            return 'autocomplete_off';
        
        // disable autocomplete if we have started typing a value
        // the idea is to let the customer put in their address from autocomplete if they wish,
        // but if they're going to actually type, then they should use our autocomplete.
        if (value.street || value.city || value.stateCode || value.zip)
            return 'autocomplete_off';
        
        return name;
    }
    
    const suggestionClick = (addr: AddressInputVm) => {
        addr.id = -1
        addr.isVerified = true;
        setAddress(addr);
        props.onChange(addr);
    }

    const onInputBlur = (e: React.FocusEvent<HTMLInputElement>, name: string) => {
        if (name === focusedInput && !suggestionMenuRef?.current?.contains(e.relatedTarget)) {
            // Delaying here because Safari on macOS desktop and all iOS browsers have slightly different timing
            // Seems that the onBlur event fires BEFORE the onclick event in these browsers, so there is an inprecise
            // Amount of delay that needs to be observed here. Realistically it could be as low as 101 ms from testing
            // But setting to 300 to be safe since there is no precise event we can await
            setTimeout(function() {
                setFocusedInput(undefined);
            }, 300);
        }
    }

    const shouldShowSuggestedAddresses = () => {
        return focusedInput && ((isMobile && focusedInput === 'street') || (!isMobile && focusedInput !== 'state'));
    }
    
    const suggestedAddressRow = (addr: AddressInputVm, ix: number) =>
        <Button type='button' color='link' key={ix} className={styles.suggestedAddress} onClick={() => suggestionClick(addr)}>
            {addr.street}, {addr.city}, {addr.stateCode}, {addr.zip}
        </Button>
    
    return  <> 
        <div className={styles.container}>
            {/*Street*/}
            <Label className={styles.addressInput}>
                Street {props.required && <RequiredAsterisk/> }
                { isLoading && !(props.disabled ?? false) && <Spinner size='sm' className='ms-1'/> }
                <Input
                    name="streetAddress"
                    value={value.street ?? ''}
                    autoComplete={getAutoComplete('address-line1')}
                    required={props.required}
                    disabled={props.disabled}
                    valid={isVerified && !disableVerification}
                    maxLength={60}
                    invalid={!props.suppressError && props.required && !isPresent(value.street)}
                    onFocus={() => setFocusedInput('street')}
                    onBlur={(event) => onInputBlur(event, 'street')}
                    onChange={e => onChange('street', e)}/>
                {/*Address Suggestions*/}
                { !(props.disabled ?? false) && !isVerified && suggestedAddresses.length > 0  && shouldShowSuggestedAddresses() &&
                    <div className={styles.suggestedAddressesOld}
                         ref={suggestionMenuRef}>
                        { suggestedAddresses.map(suggestedAddressRow) }
                    </div>
                }
                <FormFeedback>Street is required</FormFeedback>
            </Label>
            
            {/*City*/}
            <Label className={styles.addressInput}>
                City { props.required && <RequiredAsterisk/> }
                <Input
                    name="city"
                    value={value.city ?? ''}
                    autoComplete={getAutoComplete('address-level2')}
                    required={props.required}
                    disabled={props.disabled}
                    valid={isVerified && !disableVerification}
                    maxLength={40}
                    invalid={!props.suppressError && props.required && !isPresent(value.city)}
                    onFocus={() => setFocusedInput('city')}
                    onBlur={(event) => onInputBlur(event, 'city')}
                    onChange={e => onChange('city', e)}/>
                <FormFeedback>City is required</FormFeedback>
            </Label>
            
            {/*State Code*/}
            <Label className={styles.addressInput}>
                State { props.required && <RequiredAsterisk/> }
                <Input
                    name='state'
                    type='select'
                    value={value.stateCode ?? ''}
                    autoComplete={getAutoComplete('address-level1')}
                    required={props.required}
                    disabled={props.disabled}
                    valid={isVerified && !disableVerification}
                    invalid={!props.suppressError && props.required && !isPresent(value.stateCode)}
                    onFocus={() => setFocusedInput('state')}
                    onBlur={(event) => onInputBlur(event, 'state')}
                    onChange={e => onChange('stateCode', e)}>
                    <option></option>
                    {states?.map(s => <option key={s.stateCode} value={s.stateCode}>{s.name}</option>)}
                </Input>
                <FormFeedback>State is required</FormFeedback>
            </Label>
            
            {/*Zip*/}
            <Label className={styles.addressInput}>
                Zip { props.required && <RequiredAsterisk/> }
                <Input
                    name = "zip"
                    value={value.zip ?? ''}
                    autoComplete={getAutoComplete('postal-code')}
                    required={props.required}
                    disabled={props.disabled}
                    valid={isVerified && !disableVerification}
                    maxLength={10}
                    invalid={!props.suppressError && !isValidZip(value.zip)}
                    onFocus={() => setFocusedInput('zip')}
                    onBlur={(event) => onInputBlur(event, 'zip')}
                    onChange={e => onChange('zip', e)}/>
                { props.required && !isPresent(value.zip) && <FormFeedback>Zip code is required</FormFeedback> }
                { isPresent(value.zip) && !isValidZip(value.zip) && <FormFeedback>Zip code must be either 5 or 9 digits</FormFeedback> }
            </Label>
        </div>
        { !isVerified && isAllValid(value) && !isLoading && !(props.disabled ?? false) && !isAddressEmpty(props.value) &&
            <div className='alert alert-warning'>
                {props.isShippingAddress ?
                    t('productConfiguration.standardAddressLabel.couldNotVerifyShipping') :
                    t('productConfiguration.standardAddressLabel.couldNotVerify')}
                { suggestedAddresses.length > 0 && (' ' + t('productConfiguration.standardAddressLabel.chooseSuggestedAddress'))}
            </div>
        }
    </>;
}