import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Button,
    Col,
    Form,
    Row,
    Spinner
} from "reactstrap";
import "./CheckoutForm.css"
import {ShippingInformationForm} from "../../order/ShippingInformationForm";
import {CheckoutSchema, checkoutSchema, shippingInformationSchema} from "./schema";
import {FieldPath, FormProvider, useForm, useWatch} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useState} from "react";
import {ShippingAndHandlingFields} from "../../order/ShippingAndHandlingFields";
import {Address} from "../../input/address/Address";
import {PaymentInfoFields} from "../../order/PaymentInfoFields";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {isSavedAddress} from "../../../addresses/AddressSchema";
import {
    getSelectedSavedShippingAddress,
    setIsValid,
    setSelectedShippingOption,
    setShippingAddressIsVerified,
    setValidatedShippingInformation
} from "../../order/CheckoutSlice";
import {selectCurrentConsumerUser} from "../../user/login/AuthenticationSlice";
import {validateAddress} from "../../../utils/SmartyHelper";
import {ShippingAddressChoice, SuggestedAddressModel} from "../../../addresses/SuggestedAddressModel";

type Panel = 'shipping-information' | 'shipping-options' | 'payment-info';

interface CheckoutFormProps {
	onSubmit(data: CheckoutSchema): void | Promise<void>;
}

export function CheckoutForm({onSubmit}: CheckoutFormProps) {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const [formShippingAddress, setFormShippingAddress] = useState<Address>({});
	const [currentPanel, setCurrentPanel] = useState<Panel>('shipping-information');
	const selectedSavedShippingAddress = useAppSelector(getSelectedSavedShippingAddress);
	const consumer = useAppSelector(selectCurrentConsumerUser);

	const form = useForm<CheckoutSchema>({
		resolver: zodResolver(checkoutSchema),
		mode: 'onTouched'
	});

	const {
		control,
		handleSubmit,
		formState,
		trigger,
		setValue
	} = form;

	const {
		isSubmitting
	} = formState;

	const shippingInfo = useWatch({
		control,
		name: 'shippingInformation'
	});

	useEffect(() => {
		if (!shippingInfo) {
			return;
		}
		const {shippingAddress} = shippingInfo || {};
		if (shippingAddress && !isSavedAddress(shippingAddress)) {
			setFormShippingAddress({
				street: shippingAddress.street,
				city: shippingAddress.city,
				stateCode: shippingAddress.stateCode,
				zip: shippingAddress.zip
			})
		} else if (selectedSavedShippingAddress) {
			setFormShippingAddress(selectedSavedShippingAddress);
		} else {
			setFormShippingAddress({});
		}
	}, [shippingInfo?.shippingAddress, selectedSavedShippingAddress]);

	useEffect(() => {
		dispatch(setSelectedShippingOption(null));
	}, []);

	useEffect(() => {
		if (consumer) {
			setValue('shippingInformation.firstName', consumer.firstName);
			setValue('shippingInformation.lastName', consumer.lastName);
			setValue('shippingInformation.phoneNumber', consumer.phoneNumber ?? '');

			setValue('emails', {
				email: consumer.email ?? '',
				repeatEmail: consumer.email ?? ''
			});
		}
	}, [consumer]);

	useEffect(() => {
		const {success, data} = shippingInformationSchema.safeParse(shippingInfo)

		if (success) {
			dispatch(setValidatedShippingInformation(data));
		} else {
			dispatch(setValidatedShippingInformation(null));
		}
	}, [shippingInfo]);

	// Address Verification.

	const [showSuggestShippingAddressModal, setShowSuggestShippingAddressModal] = useState(false);
	const [suggestedShippingAddress, setSuggestedShippingAddress] = useState<Address | undefined>();

	// When a better address is available, a modal with a suggestion is shown. When a choice is made, the
	// modal closes and continues to the next panel. Cancelling the modal returns the user to the form. 
	// When no better address is available, an error modal is shown and no action is taken on dismissal.
	const verifyShippingAddress = (namesToCheck: FieldPath<CheckoutSchema>[], nextPanelToShow: Panel) => async () => {
		// Query Smarty Streets.
		const [match, isVerified] = await validateAddress(formShippingAddress);
		setSuggestedShippingAddress(match);

		// Show modal if necessary.
		if (!isVerified) {
			setShowSuggestShippingAddressModal(true);
			return;
		}

		// Otherwise, continue.
		const showNextPanel = showPanelIfValid(namesToCheck, nextPanelToShow);
		await showNextPanel();
	};
	const onConfirmShippingAddress = async (choice: ShippingAddressChoice) => {
		setShowSuggestShippingAddressModal(false);
		if (suggestedShippingAddress && choice === "suggested") {
			setValue("shippingInformation.shippingAddress.street", suggestedShippingAddress.street);
			setValue("shippingInformation.shippingAddress.city", suggestedShippingAddress.city);
			setValue("shippingInformation.shippingAddress.stateCode", suggestedShippingAddress.stateCode);
			setValue("shippingInformation.shippingAddress.zip", suggestedShippingAddress.zip);
			dispatch(setShippingAddressIsVerified(true));
		} else if (choice === "enter-new") {
			return;
		}
		
		const showNextPanel = showPanelIfValid(["shippingInformation", "emails"], "shipping-options");
		showNextPanel();
	}
	// End - Address Verification.

	const showPanelIfValid = (namesToCheck: FieldPath<CheckoutSchema>[], nextPanelToShow: Panel) => async () => {
		const allIsValid = await trigger(namesToCheck);
		if (allIsValid) {
			setCurrentPanel(nextPanelToShow);
		}
	};

	const togglePanel = async (id: string) => {
		if (currentPanel === 'shipping-information') {
			if (id === 'shipping-information') {
				// Already on this panel.
			} else if (id === 'shipping-options') {
				// Validate then change.
				let isValid = await trigger("shippingInformation");
				if (isValid) {
					setCurrentPanel('shipping-options');
				}
			} else if (id === 'payment-info') {
				// Do nothing, don't let user skip.
			}
		} else if (currentPanel === 'shipping-options') {
			if (id === 'shipping-information') {
				// Let user go back.
				setCurrentPanel('shipping-information');
			} else if (id === 'shipping-options') {
				// Already on this panel.
			} else if (id === 'payment-info') {
				// Validate then let user go.
				let isValid = await trigger("shippingAndHandlingOption");
				if (isValid) {
					setCurrentPanel('shipping-options');
				}
			}
		} else if (currentPanel === 'payment-info') {
			if (id === 'shipping-information') {
				// Let user go back.
				setCurrentPanel('shipping-information');
			} else if (id === 'shipping-options') {
				// Let user go back.
				setCurrentPanel('shipping-options');
			} else if (id === 'payment-info') {
				// Already on this panel.
			}
		}
	};

	const doSubmit = async (checkout: CheckoutSchema) => {
		const valid = await trigger();
		dispatch(setIsValid(valid));
		if (valid) {
			await onSubmit(checkout);
		}
	};

	return (
		<FormProvider {...form}>
			<Form onSubmit={handleSubmit(doSubmit)}>
				<Accordion open={currentPanel} toggle={togglePanel}>
					<AccordionItem>
						<AccordionHeader targetId={"shipping-information"}>
							Shipping Information
						</AccordionHeader>
						<AccordionBody accordionId={"shipping-information"}>
							<ShippingInformationForm/>
							<CheckoutFormPanelButtons
								onContinue={verifyShippingAddress(["shippingInformation", "emails"], "shipping-options")}/>
						</AccordionBody>
					</AccordionItem>
					<AccordionItem>
						<AccordionHeader targetId={"shipping-options"}>
							Shipping Method
						</AccordionHeader>
						<AccordionBody accordionId={"shipping-options"}>
							<ShippingAndHandlingFields toAddress={formShippingAddress}/>
							<CheckoutFormPanelButtons
								className="mt-4"
								onContinue={showPanelIfValid(["shippingAndHandlingOption"], "payment-info")}/>
						</AccordionBody>
					</AccordionItem>
					<AccordionItem>
						<AccordionHeader targetId="payment-info">
							Payment Info
						</AccordionHeader>
						<AccordionBody accordionId="payment-info">
							<PaymentInfoFields/>
							<Row className="mt-4">
								<Col className="cart-checkout-payment-disclaimer">
									{t(`cart.paymentDisclaimer`)}
								</Col>
								<Col>
									<Button color="primary"
									        type="submit"
									        disabled={isSubmitting}
									        className="cart-checkout-proceed-button">
										Place Order
										{isSubmitting &&
                                            <Spinner/>}
									</Button>
								</Col>
							</Row>
						</AccordionBody>
					</AccordionItem>
				</Accordion>

				<SuggestedAddressModel suggestedAddress={suggestedShippingAddress}
				                       initialAddress={formShippingAddress}
				                       isOpen={showSuggestShippingAddressModal}
				                       onComplete={onConfirmShippingAddress}
				/>

			</Form>
		</FormProvider>
	);
}

interface CheckoutFormPanelButtonsProps {
	onContinue: () => Promise<void> | void;
	className?: string;
}

function CheckoutFormPanelButtons({
	                                  onContinue,
	                                  className = ''
                                  }: CheckoutFormPanelButtonsProps) {
	return (
		<div className={`d-flex justify-content-end ${className}`}>
			<Button onClick={onContinue}
			        color="primary">
				Continue
			</Button>
		</div>
	);
}


