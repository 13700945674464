import React, {useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu} from 'reactstrap';
import {AddressInputVm} from "../components/input/address/Address";
import {MediaQueryTypes, useMediaQuery} from "../app/hooks";
import cn from "classnames";

interface AddressDropdownProps {
    open: boolean;
    addresses: readonly AddressInputVm[];
    onAddressSelected?: (address: AddressInputVm) => void;
}

const AddressDropdown: React.FC<AddressDropdownProps> = ({
                                                             open,
                                                             addresses,
                                                             onAddressSelected: _onAddressSelected
                                                         }) => {
    
    const [focusedIndex, setFocusedIndex] = useState(-1);

    const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);

    const dropdownMenuClassName = cn(
        'my-0',
        'py-0', {
        'w-100': isMobile,
        'w-75': !isMobile,
    });
    
    const dropdownItemClassName = cn(
        'border-bottom',
        'border-muted',
    )
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (addresses.length === 0) return;

        switch (event.key) {
            case 'ArrowDown':
                setFocusedIndex((prevIndex) =>
                    prevIndex < addresses.length - 1 ? prevIndex + 1 : 0
                );
                break;
            case 'ArrowUp':
                setFocusedIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : addresses.length - 1
                );
                break;
            case 'Enter':
                if (focusedIndex >= 0 && onAddressSelected) {
                    onAddressSelected(addresses[focusedIndex]);
                }
                break;
            default:
                break;
        }
    };

    const onAddressSelected = (addr: AddressInputVm) => {
        if (_onAddressSelected) {
            _onAddressSelected(addr);
        }
    }

    function focusClass(index: number) {
        return focusedIndex === index ? 'active' : '';
    }

    return (
        <div className="">
            {addresses.length > 0 && (
                <Dropdown isOpen={open && addresses.length > 0}
                          onKeyDown={handleKeyDown}
                >
                    <DropdownMenu className={dropdownMenuClassName}>
                        {addresses.map((address, index) => (
                            <DropdownItem
                                key={address.id}
                                onMouseEnter={() => setFocusedIndex(index)}
                                onClick={() => onAddressSelected && onAddressSelected(address)}
                                className={`${focusClass(index)} ${dropdownItemClassName}`} 
                            >
                                <SuggestedAddressRow address={address}/>
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            )}
        </div>
    );
};

export default AddressDropdown;


interface SuggestedAddressRowProps {
    address: AddressInputVm;
}

export const SuggestedAddressRow: React.FC<SuggestedAddressRowProps> = ({address}) => {
    return (
        <div>
            <p className="py-0 my-0">{address.street}</p>
            <p className="py-0 my-0">
                {address.city}, {address.stateCode} {address.zip}
            </p>
        </div>
    );
};