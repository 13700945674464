import {useFormContext} from "react-hook-form";
import {Col, Row} from "reactstrap";
import {formatPhoneNumber} from "../../utils/Utils";
import React, {useEffect} from "react";
import {CheckoutSchema} from "../cart/checkout/schema";
import {FormInputField} from "../forms/FormInputField";
import {FormAddressInput} from "../../addresses/FormAddressInput";
import {isUsingAdminSite} from "../../utils/SiteHelper";
import {useGetAddressesByConsumerIdQuery, useGetCurrentCartCheckAddressQuery} from "../../app/apiSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectCurrentConsumerUser} from "../user/login/AuthenticationSlice";
import {isSavedAddress} from "../../addresses/AddressSchema";
import {setSelectedSavedShippingAddress, setShippingAddressIsVerified} from "./CheckoutSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {useShouldUseCheckAddress} from "../../checkout/hooks";

interface ShippingInformationFormProps {
}

export const ShippingInformationForm = ({}: ShippingInformationFormProps) => {

    const isAdminSite = isUsingAdminSite();
    const dispatch = useAppDispatch();

    const consumer = useAppSelector(selectCurrentConsumerUser);

    const {data: savedAddresses = []} = useGetAddressesByConsumerIdQuery(consumer?.id ?? skipToken);
    const {data: checkAddress} = useGetCurrentCartCheckAddressQuery();

    const {
        control,
        watch,
        getValues
    } = useFormContext<CheckoutSchema>();

    useEffect(() => {
        const address = getValues('shippingInformation.shippingAddress');

        if (!address) return;

        if (address && !isSavedAddress(address)) {
            dispatch(setSelectedSavedShippingAddress(null));
            return;
        }

        const savedAddress = savedAddresses.find(a => a.id == address.id);
        if (savedAddress) {
            dispatch(setSelectedSavedShippingAddress(savedAddress));
        }

    }, [savedAddresses, watch('shippingInformation.shippingAddress.id')]);

    const shouldUseCheckAddress = useShouldUseCheckAddress(control);

    function handleVerificationComplete(verified: boolean) {
        dispatch(setShippingAddressIsVerified(verified))
    }

    return (
        <>
            <Row>
                <Col>
                    <FormInputField control={control}
                                    name="shippingInformation.firstName"
                                    label="First Name"
                                    autoComplete={isAdminSite ? "autocomplete_off" : "given-name"}
                                    requiredAsterisk
                    />
                </Col>
                <Col>
                    <FormInputField control={control}
                                    name="shippingInformation.lastName"
                                    label="Last Name"
                                    autoComplete={isAdminSite ? "autocomplete_off" : "family-name"}
                                    requiredAsterisk
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInputField control={control}
                                    name="emails.email"
                                    label="Email"
                                    type="email"
                                    autoComplete={isAdminSite ? "autocomplete_off" : "email"}
                                    requiredAsterisk
                    />
                </Col>
                <Col>
                    <FormInputField control={control}
                                    name="emails.repeatEmail"
                                    label="Re-enter Email"
                                    type="email"
                                    autoComplete={"autocomplete_off"}
                                    requiredAsterisk
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInputField control={control}
                                    name="shippingInformation.phoneNumber"
                                    type="tel"
                                    placeholder='(   ) ___-____'
                                    label="Phone Number"
                                    parseValue={value => formatPhoneNumber(value)}
                                    autoComplete={isAdminSite ? "autocomplete_off" : "tel-national"}
                                    requiredAsterisk
                    />
                </Col>
            </Row>

            <FormAddressInput control={control}
                              name="shippingInformation.shippingAddress"
                              label="Shipping Address"
                              section="shipping"
                              readOnly={shouldUseCheckAddress}
                              onVerificationComplete={handleVerificationComplete}
                              requiredAsterisk
                              showVerification={false}
                              before={checkAddress &&
                                  <FormInputField type="checkbox"
                                                  control={control}
                                                  name="shippingInformation.useCheckAddress"
                                                  label="Use the same address that's listed on check"
                                  />}
            />
        </>
    );
};
