import {Link, useSearchParams} from "react-router-dom";
import {DateTime} from "luxon";
import {useEffect, useState} from "react";
import {CellDisplayFunc, ColumnConfig, GridConfig} from "../admin/shared/Grid";
import {UseQuery} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {FetchBaseQueryError, QueryDefinition} from "@reduxjs/toolkit/query";
import {BaseQueryFn, FetchArgs} from "@reduxjs/toolkit/query/react";
import {SortState} from "../../utils/Grid";
import {downloadWithAuth, Utils} from "../../utils/Utils";
import './ReportView.css';
import {CurrencyFormatter} from "../../utils/CurrencyFormatter";
import {ReportParams} from "./ReportParams";
import {useGetSitesQuery} from "../../app/apiSlice";
import {GridPage} from "../admin/shared/GridPage";

// this is just a type alias for rtkqueries that take in a DateRange as the arg with T being the generic report return type
type ReportQuery<T> =  UseQuery<QueryDefinition<ReportParams, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>, string, T[], "api">>

type ReportValue = string | number | boolean;

// some useful functions for mapping typical report output into strings
export const reportDateToString = (val?: ReportValue) =>
    val ? DateTime.fromISO(val.toString()).toLocaleString(DateTime.DATE_SHORT) : '';

export const reportYesNo = (val?: ReportValue) => val === true ? 'Yes' : 'No';

export const reportTaxFileNameToFriendlyLink = (val?: ReportValue) => {
  const host = 'https://ecommerce-static.mainstreetinc.com';
  const suffix = typeof(val) === 'string' && val?.toString().includes('/') ? val.toString().split('/').at(-1) ?? '' : val?.toString() ?? '';
  return <a href={`${host}/${val}`}>{suffix}</a>;
}

export const reportMoneyToString = (val?: ReportValue) =>
    typeof(val) === 'number' ? CurrencyFormatter.format(val) : ''

export const reportPipeDelimitedList = (val?: ReportValue) =>
  typeof(val) === 'string' && val.endsWith(' | ') ? val.substring(0, val.length - 3) : (typeof(val) === 'string' ? val : '')

/**
 * @param valMapper: function that receives the cell value and returns a string route that the link will point to
 * @returns function that receives the cell value and returns a Link that opens in a new tab
 */
const reportValToLink = (valMapper: (val?: ReportValue) => string) => (val?: ReportValue) =>
    val ? <Link to={valMapper(val)}>{val}</Link> : '';
export const reportValToOrderLink = reportValToLink(id => `/admin/order/${id}`);
export const reportValToConsumerLink = reportValToLink(id => `/admin/consumer/${id}`);


export function reportCol<T>(field: string, display: string, cellDisplay?: CellDisplayFunc<T>): ColumnConfig<T> {
  return new ColumnConfig<T>({
    field,
    display,
    isSearchCriteria: false,
    cellDisplay,
  });
}

interface ReportViewProps<T> {
  title: string,
  query: ReportQuery<T>,
  columns: ColumnConfig<T>[],
  initialSort: SortState<T>,
  filterFunc?: (search: string, records: T[]) => T[],
  downloadPath: string,
  downloadName?: string
}

/**
 * This is an out-of-the-box quick Grid. If you need more customization, use Grid.
 * But, you can totally use this if your only query requirement is `ReportParams` (beginDate, endDate, & optional siteId)
 * @param title string title to be displayed when printing the report
 * @param query RTKQuery function that accepts a `ReportParams` and returns `T[]`
 * @param columns Just like a GridConfig, but with less boilerplate, only columns needed
 * @param initialSort
 * @param filterFunc Function to filter given records with a give (already lowercased) search string
 * @param downloadName *(Optional)* Name for downloaded file, defaults to `title`. Don't include the file extension, that is provided for you.
 * @param downloadPath Path to controller method for downloading this report
 */
export const ReportView = <T,>({ title, query, columns, initialSort, filterFunc, downloadPath, downloadName = title }: ReportViewProps<T>) => {
  const chunkSize = 50;
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { data: sites } = useGetSitesQuery();

  const siteIdParam = Number.parseInt(searchParams.get('siteId') ?? '');
  let criteria = {
    date: {
      beginDate: searchParams.get('beginDate') ?? DateTime.now().set({ day: 1 }).toISODate(),
      endDate: searchParams.get('endDate') ?? DateTime.now().toISODate(),
    },
    siteId: isNaN(siteIdParam) || siteIdParam === -1 ? undefined : siteIdParam,
  }

  const [defaultCriteria, setDefaultCriteria] = useState(criteria);
  const { data: queryReport, isFetching } = query({ beginDate: criteria.date.beginDate, endDate: criteria.date.endDate, siteId: criteria.siteId });
  const [ search, setSearch ] = useState('');
  const [ sort, setSort ] = useState<SortState<T>>(initialSort);
  const [ sortedReport, setSortedReport ] = useState(queryReport);
  // By storing the display values separately from the sort values, we can only render the min amount of records but still sort the result set as a whole
  const [sortedReportValues, setSortedReportValues] = useState<T[]>([]);
  const downloadUrl = `${downloadPath}?format=csv&beginDate=${criteria.date.beginDate}&endDate=${criteria.date.endDate}` + (criteria.siteId ? `&siteId=${criteria.siteId}` : '');
  const selectedSiteName = sites?.find(s => s.id === criteria.siteId)?.name ?? 'All Storefronts';
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [moreAvailable, setMoreAvailable] = useState<boolean>(true);

  // sort
  useEffect(() => {
    setSortedReport(report => {
      const workingReport = [ ...report ?? queryReport ?? []];
      return workingReport.sort(Utils.sortBy(sort.column, sort.direction));
    });
  }, [ sort, queryReport ]);

  // filter
  useEffect(() => {
    // use all records if search is blank or not filtering ever
    if (!filterFunc || search.trim() === '') {
      setSortedReport(queryReport);
      return;
    }

    const lowerSearch = search.toLowerCase();
    setSortedReport(filterFunc(lowerSearch, queryReport ?? []));
  }, [ search, queryReport, filterFunc ]);

  // set display values
  useEffect(() => {
    setSortedReportValues(sortedReport?.slice(0, chunkSize) ?? []);
    setLastIndex(chunkSize);
  }, [ sortedReport ]);

  const displayMore = () => {
    const newLastIndex = lastIndex + chunkSize;
    const moreAvailableNow = newLastIndex + 1 < (sortedReport?.length ?? 0);
    if (!moreAvailable || !sortedReport) return;
    setSortedReportValues(sortedReport?.slice(0, newLastIndex) ?? []);
    setLastIndex(newLastIndex);
    setMoreAvailable(moreAvailableNow);
  };

  const onSortResetAndSet = (newSort: SortState<T>) => {
      const newLastIndex = chunkSize;
      const moreAvailableNow = newLastIndex + 1 < (sortedReport?.length ?? 0);
      setLastIndex(newLastIndex);
      setMoreAvailable(moreAvailableNow);
      setSort(newSort);
  };

  const config: GridConfig<T> = {
    configSections: [
      {
        columns: [
          ...columns,
          // Add on report params columns as hidden fields
          new ColumnConfig<T>({
            field: 'date',
            display: 'Date',
            isHidden: true,
            isSearchCriteria: true,
            type: 'date',
          }),
          new ColumnConfig<T>({
            field: 'siteId',
            display: 'Storefront',
            isHidden: true,
            isSearchCriteria: true,
            type: 'select',
            choices: [ { value: -1, display: 'All Storefronts' }, ...(sites?.map(s => ({ value: s.id, display: s.name })) ?? [])]
          }),
        ]
      }
    ]
  }

  const onDownload = async () =>
      await downloadWithAuth(downloadUrl, `${downloadName}.csv`);

  return (<>
    <div className="report-title d-flex align-items-baseline justify-content-between">
      <h3>{title} - {selectedSiteName}</h3>
      <span>{criteria.date.beginDate} to { criteria.date.endDate}</span>
    </div>
    <GridPage
      gridConfig={config}
      title={title}
      defaultCriteria={defaultCriteria}
      // don't provide any records if we're still fetching, so Grid will show the spinner.
      records={isFetching ? [] : (sortedReportValues ?? [])}
      sort={sort}
      onShouldDisplayMore={displayMore}
      moreRecordsAvailable={moreAvailable}
      onSortChanged={onSortResetAndSet}
      shouldShowCriteriaPanel={true}
      onCriteriaChange={criteria => setSearchParams({ beginDate: criteria.date.beginDate, endDate: criteria.date.endDate, siteId: criteria.siteId})}
      onSearchChange={filterFunc ? setSearch : undefined}
      onDownload={downloadUrl ? onDownload : undefined}
      isLoading={isFetching}
      printable/>
  </>)
}
