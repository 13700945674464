import {CurrencyFormatter} from "../../utils/CurrencyFormatter";
import {CartPricing, SiteProductVariantPricing} from "../cart/CartPricing";
import {CartProductVM} from "../cart/CartProduct";

interface OrderSummaryFieldsProps {
    cartProducts: CartProductVM[];
    cartPricing?: CartPricing;
    shouldShowShipping: boolean;
    includeHandling?: boolean;
}

export const OrderSummaryFields = ({ cartProducts, cartPricing, shouldShowShipping, includeHandling = true }: OrderSummaryFieldsProps) => {
    const getTotal = () => {
        return cartPricing?.total ?? 0;
    }

    const getSubtotal = () => {
        if (!includeHandling) {
            return ((cartPricing?.subTotal ?? 0) - (cartPricing?.handlingTotal ?? 0));
        }
        return cartPricing?.subTotal ?? 0;
    }

    const getTaxTotalNoLineItems = () => {
        return cartPricing?.retailTaxAmountWithoutLineItems ?? 0;
    }

    const getShippingTotal = () => {
        return (cartPricing?.retailShippingTotal ?? 0);
    }

    const getHandlingTotal = () => {
        return (cartPricing?.handlingTotal ?? 0);
    }

    const getCartProductTotal = (siteProductVariantPricing: SiteProductVariantPricing) => {
        return siteProductVariantPricing.productTotal ?? 0;
    }

    const getSubtotalElement = () => {
        if (!cartPricing?.subTotal || cartPricing?.subTotal <= 0) {
            return <> </>
        }

        return (
            <>
                <hr/>

                <div className="cart-overview-subtotal bolded-text">
                    <span>Subtotal:</span>
                    <span className="cart-overview-price">{CurrencyFormatter.format(getSubtotal())}</span>
                </div>
            </>
        );
    }

    const getShippingElement = () => {
        return (
            <>
                <hr/>

                <div className="cart-overview-subtotal bolded-text">
                    <span>Shipping:</span>
                    <span className="cart-overview-price">{CurrencyFormatter.format(getShippingTotal())}</span>
                </div>
            </>
        );
    }

    const getHandlingElement = () => {
        if (!cartPricing?.handlingTotal || cartPricing?.handlingTotal <= 0) {
            return <></>
        }

        return (
            <>
                <hr/>

                <div className="cart-overview-subtotal bolded-text">
                    <span>Handling:</span>
                    <span className="cart-overview-price">{CurrencyFormatter.format(getHandlingTotal())}</span>
                </div>
            </>
        );
    }

    const getDiscountElement = () => {
        if (typeof cartPricing?.discounts === 'undefined' || cartPricing?.discounts.length <= 0) {
            return <></>
        }

        return (
            <>
                <hr/>

                <div className="cart-overview-discount-container">
                    <span className="bolded-text">Discounts:</span>
                    <div className="cart-overview-discount-detail-row">
                        {
                            cartPricing?.discounts.map((d) => {
                                const discountAmount = - (d.amount ?? 0)

                                return (
                                    <>
                                        <span>{d.description ?? ""}</span>
                                        <span className="cart-overview-price">{CurrencyFormatter.format(discountAmount)}</span>
                                    </>
                                );
                            })
                        }
                    </div>
                </div>
            </>
        );
    }
    
    const getTaxTotalElement = () => {
        if (!cartPricing?.taxAmount || cartPricing?.taxAmount <= 0) {
            return <></>
        }

        return (
            <>
                <hr/>

                <div className="cart-overview-subtotal bolded-text">
                    <span>Tax:</span>
                    <span className="cart-overview-price">{CurrencyFormatter.format(getTaxTotalNoLineItems())}</span>
                </div>

                {
                    cartPricing?.taxLineItems.map((tli) => {
                        return (
                            <div key={`${tli.description}-${tli.amount}`}
                                 className="cart-overview-subtotal bolded-text">
                                <span>{tli.description ?? ""}</span>
                                <span className="cart-overview-price">{CurrencyFormatter.format(tli.amount ?? 0)}</span>
                            </div>
                        )
                    })
                }
            </>
        );
    };
    
    const getTotalElement = () => {
        if (cartPricing?.taxAmount && cartPricing?.taxAmount > 0) {
            return (
                <>
                    <hr/>

                    <div className="cart-overview-subtotal bolded-text">
                        <span>Total:</span>
                        <span className="cart-overview-price">{CurrencyFormatter.format(getTotal())}</span>
                    </div>
                </>
            );
        }
    };

    const getCartProductQuantityDisplay = (cartProductId?: number) => {
        return `- ${cartProducts.find(cp => cp.id === cartProductId)?.quantityOption?.description}`;
    };
    
    return (
        <>
            <div className="cart-overview-header">
                <h3>Your Cart</h3>
                <span className="cart-overview-item-count">{cartProducts?.length} item{cartProducts?.length===1?"":"s"}</span>
            </div>

            <hr/>

            <div className="cart-overview-subtotal">
                <h5>Products:</h5>
            </div>

            {cartPricing?.siteProductVariantTotals?.map((spvt) => {
                return (
                    <div className="cart-overview-subtotal" key={`${spvt.cartProductId}-${spvt.subTotal}`}>
                        <span>{spvt.name} {getCartProductQuantityDisplay(spvt.cartProductId)}</span>
                        <span className="cart-overview-price">{CurrencyFormatter.format(getCartProductTotal(spvt))}</span>
                    </div>
                )
            })}

            {shouldShowShipping && getShippingElement()}
            {includeHandling && getHandlingElement()}

            {getSubtotalElement()}

            {getDiscountElement()}
            {getTaxTotalElement()}

            {getTotalElement()}
        </>
    );
};
