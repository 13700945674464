import './CartOverview.css';
import {CartProductVM} from "../CartProduct";
import {CartPricing} from "../CartPricing";
import {OrderSummaryFields} from "../../order/OrderSummaryFields";

interface ProductPreviewProps {
    cartProducts: CartProductVM[];
    cartPricing?: CartPricing;
    shouldShowShipping: boolean;
    includeHandling?: boolean;
}

export const CartOverview = (props: ProductPreviewProps) => {
    return (
        <div className="cart-overview-container" >
            <OrderSummaryFields {...props} />
        </div>
    );
}