import './CartReview.css';
import {useTranslation} from "react-i18next";
import {Button, Container} from "reactstrap";
import {
	useAddProductToCartMutation,
	useCreateCartCheckoutEventMutation,
	useGetCartProductsPricingQuery,
	useGetCartProductsQuery,
	useRemoveAllProductsFromCartMutation,
	useRemoveProductFromCartMutation
} from "../../../app/apiSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectCartId} from "../CartSlice";
import {CartProductView} from "../productView/CartProductView";
import {CartOverview} from "../overview/CartOverview";
import ScrollToTop from "../../helpers/ScrollToTop";
import {SiteProductVariantQuantityOption} from "../../product/SiteProductVariantQuantityOption";
import {Link} from "react-router-dom";
import {SiteProductVariantPricing} from "../CartPricing";
import {showConfirmationModal} from "../../modal/ModalSlice";
import {StickySidebar} from '../StickySidebar';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AnalyticsTools} from "../../../utils/AnalyticsHelper";

export const CartReview = () => {
	const {t} = useTranslation();

	const cartId = useAppSelector(selectCartId);
	const {data: cartProducts} = useGetCartProductsQuery(cartId);
	const {data: cartPricing} = useGetCartProductsPricingQuery({cartId: cartId});
	const [removeAllProductFromCart] = useRemoveAllProductsFromCartMutation();
	const [updateCartProduct] = useAddProductToCartMutation();
	const [createCartCheckoutEvent] = useCreateCartCheckoutEventMutation();
	const [removeProductFromCart] = useRemoveProductFromCartMutation();
	const dispatch = useAppDispatch();

	const confirmRemoveAll = async () => {
		if (cartId) await removeAllProductFromCart(cartId).unwrap();
	}

	async function confirmRemoveCartProduct(cartProductId: number) {
		const removedCartProduct = cartProducts?.find(cp => cp.id === cartProductId);
		await removeProductFromCart(cartProductId).unwrap();
		if (removedCartProduct) {
			AnalyticsTools.recordRemoveToCartEvent(removedCartProduct);
		}
	}

	const onRemoveAllClick = async () => {
		dispatch(showConfirmationModal({
			title: 'Remove All Products',
			content: 'Are you sure you want to remove all products from the cart?',
			affirmText: 'Yes, Remove',
			onConfirm: confirmRemoveAll,
		}));
	}

	const onDeleteClick = async (cartProductId: number) => {
		dispatch(showConfirmationModal({
			title: 'Remove Product',
			content: 'Are you sure you want to remove this product from the cart?',
			affirmText: 'Yes, Remove',
			onConfirm: () => confirmRemoveCartProduct(cartProductId),
		}))
	}

	const getRemoveAllButton = () => {
		return (cartProducts && cartProducts?.length > 0) ?
			<Button onClick={onRemoveAllClick}>Remove all</Button> : <></>;
	}

	const onQuantityChanged = (cartProductId: number, quantityOption: SiteProductVariantQuantityOption) => {
		const updatedCartProductIndex = cartProducts?.findIndex(cp => cp.id === cartProductId);
		if (updatedCartProductIndex !== undefined && updatedCartProductIndex > -1 && cartProducts) {
			updateCartProduct({
				cartProductId: cartProducts[updatedCartProductIndex].id
				, siteProductVariantId: cartProducts[updatedCartProductIndex].siteProductVariantId
				, siteProductVariantQuantityOptionId: quantityOption.id
			});
		}
	}

	const getSiteProductVariantPricing = (cartProductId: number): SiteProductVariantPricing | undefined =>
		cartPricing?.siteProductVariantTotals?.find(spvt => spvt.cartProductId === cartProductId);

	const onProceedToCheckoutClicked = async () => {
		if (cartId)
			await createCartCheckoutEvent(cartId).unwrap();
	}

	return (
		<span className="cart-review-container">
			<ScrollToTop></ScrollToTop>
			<Container>

				<span className="cart-review-content-container">

					<span className="cart-review-lane-one">
						<span className='cart-review-header-container'>
							<span className="cart-view-header-group">
								<span className="cart-review-title">{t(`cart.title`)}</span>
								<span
									className="cart-review-item-count">({cartProducts?.length ?? 0} item{cartProducts?.length === 1 ? '' : 's'})</span>
							</span>
							<div className='remove-all-button hide-on-desktop'>
								{getRemoveAllButton()}
							</div>
						</span>
						{cartProducts?.map((cp) => {
							return <CartProductView
								key={cp.id}
								cartProduct={cp}
								siteProductVariantPricing={getSiteProductVariantPricing(cp.id)}
								onQuantityChanged={onQuantityChanged}
								onDeleteClick={onDeleteClick}
							></CartProductView>
						})}
					</span>


					<span className="cart-review-lane-two">
						<StickySidebar>
							<>
								<div className='remove-all-button hide-on-mobile'>
									{getRemoveAllButton()}
								</div>

								<CartOverview
									cartProducts={cartProducts ?? []}
									cartPricing={cartPricing}
									shouldShowShipping={false}
									includeHandling={false}
								/>

								<Link to={`${!cartProducts || cartProducts.length === 0 ? "#" : "/checkout"}`}>
									<Button color="primary"
											disabled={!cartProducts || cartProducts.length === 0}
											className="cart-review-proceed-button"
											onClick={onProceedToCheckoutClicked}>
										<h6>Proceed to Checkout</h6>
										<FontAwesomeIcon icon={faArrowRight}/>
									</Button>
								</Link>

								<div className="cart-review-shipping-disclaimer">{t(`cart.shippingDisclaimer`)}</div>
							</>
						</StickySidebar>
					</span>

				</span>

			</Container>
		</span>
	)
}
