import {FormAddressInput} from "../../addresses/FormAddressInput";
import {ProductConfigurationInputProps} from "./ProductConfigurationInputProps";
import {FormPhoneNumberInput} from "../../components/forms/FormPhoneNumberInput";
import {useFormContext} from "react-hook-form";

import {shouldRevalidate} from "../validation/utils";
import {useAppSelector} from "../../app/hooks";
import {selectCurrentConsumerUser} from "../../components/user/login/AuthenticationSlice";
import {useGetAddressesByConsumerIdQuery} from "../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {AddressVm} from "../../components/input/address/Address";
import {useEffect} from "react";
import {isAddressEmpty} from "../../utils/Utils";
import {curry, find, pick} from "lodash/fp";
import {InfoLabel} from "./InfoLabel";
import {getAllConfigs} from "../ProductConfigurationSlice";
import {isFieldDisabledFromReferencedConfigs} from "../FieldModifiers";

interface AddressInputProps extends ProductConfigurationInputProps {

}

const DEFAULT_CONSUMER_ADDRESSES: AddressVm[] = [];

export function AddressInput({
                                 config
                             }: AddressInputProps) {


    const consumer = useAppSelector(selectCurrentConsumerUser);
    const allConfigs = useAppSelector(getAllConfigs);
    const {data: consumerAddresses = DEFAULT_CONSUMER_ADDRESSES} = useGetAddressesByConsumerIdQuery(consumer?.id ?? skipToken)

    const {
        trigger,
        getFieldState,
        setValue,
        getValues,
    } = useFormContext();
    
    const { isDisabled: fieldDisabled, ruleFailedMessage } = isFieldDisabledFromReferencedConfigs({ config, allConfigs, formValues: getValues() });

    useEffect(() => {
        const address = getValues(config.configurationKey);

        if (!isAddressEmpty(address)) {
            setValue(config.configurationKey, address);
        }
        else if (address?.id > -1) {
            const savedAddress = find(byId(address.id), consumerAddresses);
            if (savedAddress) {
                setValue(config.configurationKey, pickAddressFormFields(savedAddress));
            }
        }
        else {
            setValue(config.configurationKey, pickAddressFormFields(consumerAddresses[0]));
        }
    }, [consumerAddresses]);
    
    useEffect(() => {
        if (fieldDisabled) {
            setValue(`${config.configurationKey}.phoneNumber`, "", { shouldValidate: true });
        }
    }, [fieldDisabled]);
    
    const handleVerificationComplete = (verified: boolean) => {
        setValue(`${config.configurationKey}.isVerified`, verified);
    };

    const revalidate = async (field: string) => {
        if (shouldRevalidate(getFieldState(field))) {
            await trigger(field);
        }
    }

    return (
        <>
            <FormAddressInput
                name={config.configurationKey}
                zipLabel="Zip Code"
                onChange={async (field, value) => {
                    await revalidate(`${config.configurationKey}.${field}`);
                    return value;
                }}
                onVerificationComplete={handleVerificationComplete}
                showVerification={false}
                after={
                <>
                    <FormPhoneNumberInput label={'Phone (Optional)'}
                                             name={`${config.configurationKey}.phoneNumber`}
                                             disabled={fieldDisabled}
                                             requiredAsterisk={false}
                                             parseValue={async (value) => {
                                                 await revalidate(`${config.configurationKey}.phoneNumber`);
                                                 return value;
                                             }}
                />
                    {fieldDisabled && <div><InfoLabel>{ruleFailedMessage}</InfoLabel></div>}
                    </>
            }
            />
        </>
    );
}

const byId = curry((id: number, address: AddressVm) => address.id == id);

const pickAddressFormFields = pick([
    'street',
    'stateCode',
    'city',
    'zip',
    'id'
])
